header.header {
  background-image: url('/img/bg-intro.jpg');
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  & .bg-metal {
    background-image: url('/img/bg-metal.jpg');
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: -1.5em;
  }

  & img.sponsor {

    //max-height: 2em;
    //max-width: 144px;

  }
}

@media (min-width: 992px) {
  header.header {
    min-height: 100vh;
    //min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
  }

  header.header .header-content {
    margin-bottom: 0;
    text-align: left;
  }
}

#go-down {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto .2rem;
  color: white;
}

.section-heading {
  margin-top: 3em;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {

    padding-left: 0 !important;
  }
}