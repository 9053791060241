.border-program{
  border-width: thick !important;
}

img.sponsor{
   max-height: 200px;
   max-width: 200px;
 }

img.exhibitor{
  max-height: 120px;
  max-width: 180px;
}


section#sponsors{

  div.sponsor-presenting img{
    max-height: 160px;
    max-width: 160px;
  }

  div.sponsor-supporting img{
    max-height: 100px;
    max-width: 100px;
  }
}

.dates{
  font-size: 1.5em;
}

div.sponsor-platinum img{
  max-width: 260px;
}