nav#navprogram {
  overflow: auto;
  font-size: 0.9rem;

  .nav-link {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    //padding-top: 0 !important;
    //padding-bottom: 0 !important;
  }

  &.sticky-top {
    top: 70px !important
  }

}