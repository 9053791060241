footer {
  padding: 1em 0;

  p, a, a:hover, a:focus, a:active, a.active {
    font-size: 0.8rem;
    color: $gray-200;
    text-decoration: none;
  }

  a:hover, a:focus, a:active, a.active {
    text-decoration: underline !important;
  }
}

.sponsor-footer img {
  filter: grayscale(100%);
  max-height: 90px !important;
}